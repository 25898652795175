<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите сотрудника"
                        label="Сотрудник"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isEmployeeIdError"
                            item-key="employeeName"
                            :options="employees"
                            placeholder="Сотрудник"
                            :searchable="true"
                            :values="[state.employeeId]"
                            @on-selected="onEmployeeSelected" />
                    </form-group-ui>
                </div>
            </div>
            <div
                v-for="(tool, toolInd) in state.tools"
                :key="toolInd"
                class="row mb-4 border-bottom">
                <div class="col-auto flex-grow-1 flex-shrink-1">
                    <form-group-ui
                        invalid-feedback="Выберите инструмент"
                        label="Инструмент"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isToolIdError(tool)"
                            item-key="name"
                            :options="tools"
                            placeholder="Инструмент"
                            :searchable="true"
                            :values="[tool.id]"
                            @on-selected="(v) => onToolSelected(tool, v)" />
                    </form-group-ui>
                </div>
                <div class="col-auto flex-grow-1 flex-shrink-1">
                    <form-group-ui
                        invalid-feedback="Укажите количество"
                        label="Количество"
                        required>
                        <input-ui
                            v-model="tool.count"
                            :is-invalid="state.visibleErrors && isCountError(tool)"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            placeholder="Количество" />
                    </form-group-ui>
                </div>
                <div v-if="state.tools.length > 1" class="col-auto flex-grow-0 flex-shrink-0">
                    <div class="mb-3">
                        <label class="form-label d-block" style="color: #fff">.</label>
                        <button-ui
                            class="btn-outline-danger"
                            type="button"
                            @click="deleteTool(toolInd)">
                            x
                        </button-ui>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12">
                    <button-ui
                        class="btn-outline-secondary"
                        type="button"
                        @click="addTool">
                        Добавить инструмент
                    </button-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';

export default defineComponent({
    name: "ToolEmployeeModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const tools = ref([]);
        const employees = ref([]);

        const state = reactive({
            title: '',
            btn: '',
	        id: 0,
	        tools: [
		        {
                    id: 0,
			        count: '0',
		        },
	        ],
	        toolId: 0,
	        employeeId: 0,
	        count: '0',
            visibleErrors: false,
            loader: false,
        });

	    const isToolIdError = computed(() => (tool: any) => !tool.id);
	    const isEmployeeIdError = computed(() => !state.employeeId);
	    const isCountError = computed(() => (tool: any) => !parseInt(tool.count));

        const errors = computed(() => {
            let toolIds = state.tools.filter((i: any) => !i.id);
            let counts = state.tools.filter((i: any) => !parseInt(i.count));
            return isEmployeeIdError.value || toolIds.length || counts.length;
        });

        onMounted(() => {
            if (modal.data.type === 'add') {
                state.title = 'Выдача инструмента';
                state.btn = 'Выдать';
            } else {
	            // state.title = 'Редактирование сотрудника';
	            // state.btn = 'Сохранить';

                // state.id = modal.data.employee.employeeId;
                // state.name = modal.data.employee.employeeName;
                // state.organizationId = modal.data.employee.organizationId;
                // state.subdivision = modal.data.employee.employeeSubdivision;
                // state.plot = modal.data.employee.employeePlot;
                // state.post = modal.data.employee.employeePost;
                // state.workingModeId = modal.data.employee.employeeWorkingMode;
            }

	        api.tools.get().then((r: any) => {
		        tools.value = r.data;
	        });

	        api.employees.get().then((r: any) => {
		        employees.value = r.data;
	        });
        });

	    function onToolSelected(tool: any, _tool: any) {
		    tool.id = _tool.id;
	    }

	    function onEmployeeSelected(employee: any) {
		    state.employeeId = employee.employeeId;
	    }

        function addTool() {
            state.tools.push({
                id: 0,
                count: '0',
            });
        }

        function deleteTool(ind: any) {
            state.tools.splice(ind, 1);
        }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                console.log(11111);
	            if (modal.data.type === 'add') {
		            api.toolsemployees.post({
			            employeeId: state.employeeId,
			            tools: state.tools,
		            }).then((r: any) => {
			            if (r.data) {
                            modal.ok({
                	            list: r.data,
                            });
			            }
		            }).finally(() => state.loader = false);
	            } else {
		            // api.employees.patch({
			        //     id: state.id,
			        //     name: state.name,
			        //     organizationId: state.organizationId,
			        //     subdivision: state.subdivision,
			        //     plot: state.plot,
			        //     post: state.post,
			        //     workingMode: state.workingModeId,
		            // }).then((r: any) => {
			        //     if (r.data) {
                    //         modal.ok({
                	//             employee: r.data,
                    //         });
			        //     }
		            // }).finally(() => state.loader = false);
	            }
            }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        deleteTool,
	        tools,
	        employees,
	        isToolIdError,
	        isEmployeeIdError,
	        isCountError,
	        onToolSelected,
	        onEmployeeSelected,
	        send,
	        addTool,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
