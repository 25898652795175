<template>
    <modal-ui :id="id" size="modal-xl" :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-10 col-md-4">
                    <form-group-ui invalid-feedback="Выберите дату" label="Дата" required>
                        <date-picker v-model="state.happenedAt" class="inline-block h-full" :is24hr="true" mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input class="form-control"
                                        :class="{ 'is-invalid': isHappenedAtError && state.visibleErrors, 'bg-white': true }"
                                        as readonly :timezone="'Asia/Almaty'" :value="inputValue"
                                        @blur="togglePopover()" @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-2 d-flex align-items-center"><label class="form-check form-check-inline">
                        <input v-model="state.isWorkOnDepartment" class="form-check-input" type="checkbox">
                        <span class="form-check-label">Работа в цехе</span>
                    </label></div>
                <div class="col-12 col-md-6">
                    <form-group-ui v-if="!state.isWorkOnDepartment" invalid-feedback="Укажите локомотив (секцию)"
                        label="Локомотив (секция)" required>
                        <select-ui :is-invalid="state.visibleErrors && isSectionJobIdError" :options="actualSectionJobs"
                            placeholder="Локомотив (секция)" :searchable="true" :values="[state.sectionJobId]"
                            @on-selected="onSectionJobSelected" />
                    </form-group-ui>
                    <form-group-ui v-else invalid-feedback="Укажите цех (подразделение)"
                        label="Цех (подразделение)" required>
                        <select-ui :is-invalid="state.visibleErrors && isDepartmentError" :options="departments"
                            placeholder="Цех (подразделение)" :searchable="true" :values="[state.departmentId]"
                            @on-selected="onDepartmentSelected" />
                    </form-group-ui>
                </div>
            </div>
            <div v-for="(summaryEmployee, toolInd) in state.summaryDetailsPerEmployee" :key="toolInd"
                class="row mb-4 border-bottom">
                <div class="col-12 col-md-4 col-lg-3">
                    <form-group-ui invalid-feedback="Выберите сотрудника" label="Сотрудник" required>
                        <select-ui :is-invalid="state.visibleErrors && isEmployeeIdError(summaryEmployee)"
                            :options="getEmployees(summaryEmployee.employeeId)" placeholder="Сотрудник"
                            :searchable="true" :values="[summaryEmployee.employeeId]"
                            @on-selected="(v: any) => onEmployeeSelected(summaryEmployee, v)" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <form-group-ui invalid-feedback="Укажите конструктив" label="Конструктив" required>
                        <select-ui :is-invalid="state.visibleErrors && isConstructIdError(summaryEmployee)"
                            :options="constructives" placeholder="Конструктив" :searchable="true"
                            :values="[summaryEmployee.subgroupId]"
                            @on-selected="(v: any) => onConstructSelected(summaryEmployee, v)" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-4 col-lg-2">
                    <form-group-ui invalid-feedback="Укажите количество (часы)" label="Количество (часы)" required>
                        <input-ui v-model="summaryEmployee.hoursWorked"
                            :is-invalid="state.visibleErrors && isHoursWorkedError(summaryEmployee)"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ } } }"
                            placeholder="Количество (часы)" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-auto flex-grow-1 flex-shrink-1">
                    <form-group-ui invalid-feedback="Примечание" label="Примечание">
                        <input-ui v-model="summaryEmployee.comment" placeholder="Примечание" />
                    </form-group-ui>
                </div>
                <div v-if="state.summaryDetailsPerEmployee.length > 1" class="col-auto flex-grow-0 flex-shrink-0">
                    <div class="mb-3">
                        <label class="form-label d-block" style="color: #fff">.</label>
                        <button-ui class="btn-outline-danger" type="button" @click="deleteSummaryEmployee(toolInd)">
                            x
                        </button-ui>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12">
                    <button-ui class="btn-outline-secondary" type="button" @click="addSummaryEmployee">
                        Добавить сотрудника
                    </button-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import moment from 'moment-timezone';
import api from "@/api";
import { DatePicker } from 'v-calendar';

export default defineComponent({
    name: "SectionJobSummaryModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const modal = useModal(props.id);
        const employees = ref([]);
        const actualSectionJobs = ref([]);
        const constructives = ref([]);
        const departments = ref([]);

        const state = reactive({
            sectionJobId: '',
            departmentId: '',
            isWorkOnDepartment: false,
            happenedAt: '',
            summaryDetailsPerEmployee: [
                {
                    subgroupId: '',
                    employeeId: '',
                    hoursWorked: '',
                    comment: '',
                },
            ],
            title: '',
            btn: '',
            visibleErrors: false,
            loader: false,
        });

        const getEmployees = computed(() => (employeeId?: string) => {
            return employees.value.filter((employee: any) => {
                if (employeeId && employee.id === +employeeId) {
                    return true
                }
                return state.summaryDetailsPerEmployee.every(e => +e.employeeId !== +employee.id);
            })
        })

        const isEmployeeIdError = computed(() => (summaryEmployee: any) => !summaryEmployee.employeeId);
        const isHoursWorkedError = computed(() => (summaryEmployee: any) => !summaryEmployee.hoursWorked);
        const isConstructIdError = computed(() => (summaryEmployee: any) => !summaryEmployee.subgroupId);
        const isSectionJobIdError = computed(() => state.isWorkOnDepartment ? false : !state.sectionJobId);
        const isDepartmentError = computed(() => state.isWorkOnDepartment ? !state.departmentId : false);
        const isHappenedAtError = computed(() => state.happenedAt.length === 0);

        const errors = computed(() => {
            const isHoursNotValid = state.summaryDetailsPerEmployee.some((i) => +i.hoursWorked <= 0);
            const isEmployeesNotValid = state.summaryDetailsPerEmployee.some((i) => !i.employeeId);
            const isConstructIdsNotValid = state.summaryDetailsPerEmployee.some((i) => !i.subgroupId);
            return isDepartmentError.value || isHappenedAtError.value || isConstructIdsNotValid || isSectionJobIdError.value || isHappenedAtError.value || isHoursNotValid || isEmployeesNotValid;
        });

        onMounted(() => {
            if (modal.data.type === 'add') {
                state.title = 'Заполнить сводку';
                state.btn = 'Сохранить';
            } else {
                state.title = 'Редактировать сводку';
                state.btn = 'Сохранить';

                state.sectionJobId = modal.data.summary.sectionJobId;
                state.departmentId = modal.data.summary.departmentId;
                state.isWorkOnDepartment = !modal.data.summary.sectionJobId;
                state.happenedAt = modal.data.summary.happenedAt;
                state.summaryDetailsPerEmployee = modal.data.summary.summaryDetailsPerEmployee.map((e: any) => ({
                    employeeId: e.employeeId,
                    hoursWorked: e.hoursWorked,
                    subgroupId: e.subgroupId,
                    comment: e.comment,
                }));
            }

            api.employees.get().then((r: any) => {
                employees.value = r.data?.map((em: any) => ({
                    ...em,
                    name: `${em.employeeName} (${em.employeePost})`,
                }));
            });

            api.sectionJob.getActualSectionJobsList().then((r: any) => {
                actualSectionJobs.value = r.data?.sectionJobs || [];
            });

            api.constructs.get().then((r: any) => {
                constructives.value = r.data;
            });

            api.department.get().then((r: any) => {
                departments.value = r.data;
            });
        });

        function onConstructSelected(employee: any, _c: any) {
            employee.subgroupId = _c.id;
        }

        function onSectionJobSelected(sj: any) {
            state.sectionJobId = sj.id;
        }

        function onDepartmentSelected(sj: any) {
            state.departmentId = sj.id;
        }

        function onEmployeeSelected(employee: any, _employee: any) {
            employee.employeeId = _employee.id;
        }

        function addSummaryEmployee() {
            state.summaryDetailsPerEmployee.push({
                employeeId: '',
                subgroupId: '',
                hoursWorked: '',
                comment: '',
            });
        }

        function deleteSummaryEmployee(ind: any) {
            state.summaryDetailsPerEmployee.splice(ind, 1);
        }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                if (modal.data.type === 'add') {
                    api.sectionJobSummary.create({
                        sectionJobId: state.isWorkOnDepartment ? null : state.sectionJobId,
                        departmentId: state.isWorkOnDepartment ? state.departmentId : null,
                        happenedAt: moment(state.happenedAt).tz('Asia/Almaty').hour(12).toISOString(),
                        summaryEmployees: state.summaryDetailsPerEmployee,
                    }).then((r: any) => {
                        if (r.data) {
                            modal.ok(r.data);
                        }
                    }).finally(() => state.loader = false);
                } else {
                    api.sectionJobSummary.update(modal.data.summary.id, {
                        sectionJobId: state.isWorkOnDepartment ? null : state.sectionJobId,
                        departmentId: state.isWorkOnDepartment ? state.departmentId : null,
                        happenedAt: moment(state.happenedAt).tz('Asia/Almaty').hour(12).toISOString(),
                        summaryEmployees: state.summaryDetailsPerEmployee,
                    }).then((r: any) => {
                        if (r.data) {
                            modal.ok(r.data);
                        }
                    }).finally(() => state.loader = false);
                    // api.employees.patch({
                    //     id: state.id,
                    //     name: state.name,
                    //     organizationId: state.organizationId,
                    //     subdivision: state.subdivision,
                    //     plot: state.plot,
                    //     post: state.post,
                    //     workingMode: state.workingModeId,
                    // }).then((r: any) => {
                    //     if (r.data) {
                    //         modal.ok({
                    //             employee: r.data,
                    //         });
                    //     }
                    // }).finally(() => state.loader = false);
                }
            }
        }

        function ok() {
            modal.ok({
                asd: 123,
            });
        }

        function close() {
            modal.close();
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            ok,
            cancel,
            close,
            state,
            deleteSummaryEmployee,
            onDepartmentSelected,
            employees,
            departments,
            isEmployeeIdError,
            isHoursWorkedError,
            isConstructIdError,
            isSectionJobIdError,
            isDepartmentError,
            isHappenedAtError,
            onEmployeeSelected,
            onConstructSelected,
            onSectionJobSelected,
            send,
            addSummaryEmployee,
            constructives,
            actualSectionJobs,
            getEmployees,
        };
    },
});
</script>

<style scoped lang="scss"></style>
