<template>
    <modal-ui :id="id" size="modal-md" title="Редактирование документа">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row">
                <div class="col-12 col-md-6">
                    <form-group-ui invalid-feedback="Тип не указан" label="Тип документа">
                        <select
                            class="form-select"
                            :class="{'is-invalid': state.visibleErrors && isDocumentTypeError}"
                            v-model="state.documentType"
                        >
                            <option v-for="documentType in DOCUMENT_TYPE_COLLECTION.filter(dt => dt !== DOCUMENT_TYPE.NEW)"
                                :key="documentType" :value="documentType">
                                {{ documentType }}
                            </option>
                        </select>
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-6">
                    <form-group-ui invalid-feedback="Укажите наименование" label="Наименование" required>
                        <input-ui v-model="state.name" :is-invalid="state.visibleErrors && isNameError"
                            placeholder="Наименование" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-6">
                    <form-group-ui
                        label="Контрагент">
                        <select-ui
                            item-key="name"
                            :options="contractors"
                            placeholder="Контрагент"
                            :searchable="true"
                            :values="[state.contractorId]"
                            @on-selected="onSupplierSelected" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-6">
                    <form-group-ui
                        label="Локомотив"
                        required>
                        <select-ui
                            item-key="name"
                            :disabled="!state.contractorId"
                            :options="filteredLocomotives"
                            placeholder="Локомотив"
                            :searchable="true"
                            :values="[state.locomotiveId]"
                            @on-selected="handleLocomotiveSelect">
                        </select-ui>
                    </form-group-ui>
                </div>
                <div class="col-12 mt-3">
                    <tags-input-ui :tags="state.tags" @on-tags-changed="handleChangeTag" placeholder="Тэги" />
                </div>
            </div>
            <div class="col-12 text-center mt-3">
                <button-ui button-class="btn-primary">
                    Сохранить
                </button-ui>
            </div>
        </form>
    </modal-ui>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed, ref } from "vue";
import { DOCUMENT_TYPE, DOCUMENT_TYPE_COLLECTION } from '@depo/constants/document';
import { CATEGORY_TYPE } from '@depo/constants/contractor';
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { ISupplier } from "@/@types/ISupplier";
import { ILocomotive } from "@/@types/ILocomotive";

type stateType = {
    id: number,
    visibleErrors: boolean,
    loader: boolean,
    documentId: number,
    name: string,
    locomotiveId: number | string,
    documentType: string,
    contractorId: null | number, 
    tags: Array<string>,
};

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);
const contractors = ref<ISupplier[]>([]);
const locomotives = ref<ILocomotive[]>([]);
const state: stateType = reactive({
    id: 0,
    visibleErrors: false,
    loader: false,
    name: '',
    documentType: '',
    documentNames: [],
    documentId: 0,
    locomotiveId: '',
    contractorId: null,
    tags: [],
});

const filteredLocomotives = computed(() => {
    if (!state.contractorId) {
        return [];
    }

    const contractor = contractors.value.find(c => c.id === state.contractorId);

    if (!contractor || !contractor.categoryTypes.includes(CATEGORY_TYPE.ROLLING_STOCK_OWNER) || !contractor.rollingStockOwner) {
        return [];
    }

    return locomotives.value.filter(locomotive => locomotive.contractorId === Number(contractor.rollingStockOwner.id));
});

onMounted(() => {
    if (Object.keys(modal.data.document).length) {
        state.documentId = modal.data.document.id;
        state.tags = modal.data.document.tags;
        state.documentNames = modal.data.documentNames;
        state.contractorId = modal.data.document.contractorId;
        state.documentType = modal.data.document.documentType !== DOCUMENT_TYPE.NEW ? modal.data.document.documentType : '';
        state.name = modal.data.document.fileName;
    }

    api.supplier.get().then(r => {
        contractors.value = r.data;
    });

    api.locomotives.getAll().then(data => {
        locomotives.value = data;
    }).catch(console.error);
});

const isNameError = computed(() => !state.name.length || !/[\wа-яА-Я]+/ig.test(state.name) || state.documentNames.includes(state.name));
const isDocumentTypeError = computed(() => !state.documentType);

const isErrorsExist = computed(() => isDocumentTypeError.value || isNameError.value);

function onSupplierSelected(supplier: ISupplier) {
    state.locomotiveId = '';
    if (state.contractorId === supplier.id) {
        state.contractorId = null;
    } else {
        state.contractorId = supplier.id;
    }
}

function handleLocomotiveSelect(locomotive: ILocomotive) {
    if (state.locomotiveId === locomotive.id) {
        state.locomotiveId = '';
    } else {
        state.locomotiveId = locomotive.id;
    }
}

function handleChangeTag(tags: Array<string>) {
    state.tags = tags;
}

function send() {
    if (isErrorsExist.value) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.loader = true;

    api.document.update(state.documentId, {
        documentType: state.documentType,
        tags: state.tags,
        name: state.name,
        contractorId: state.contractorId,
        locomotiveId: state.locomotiveId || null,
    }).then(r => {
        modal.ok(r.data);
    })
}

</script>

<style scoped lang="scss"></style>
