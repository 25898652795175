<template>
    <modal-ui
        :id="id"
        size="modal-lg"
        title="Список секций">
        <div>
            <table class="table table-bordered">
                <tr>
                    <th class="p-2">
                        Период
                    </th>
                    <th class="p-2">
                        Техника
                    </th>
                    <th class="p-2">
                        Клиента
                    </th>
                    <th class="p-2">
                        Вид ремонта
                    </th>
                    <th class="p-2">
                        Примечание
                    </th>
                    <th class="p-2"></th>
                </tr>
                <tr v-for="i in items" :key="i.id">
                    <td class="p-2">
                        {{ moment(i.dateStart).locale('ru').format('DD.MM.YYYY') }} - {{ moment(i.dateEnd).locale('ru').format('DD.MM.YYYY') }}
                    </td>
                    <td class="p-2">
                        {{ getLoco(i.locoModelId) }}
                    </td>
                    <td class="p-2">
                        {{ getContractor(i.contractorId) }}
                    </td>
                    <td class="p-2">
                        {{ getJob(i.jobCategoryId) }}
                    </td>
                    <td class="p-2">
                        {{ i.comment }}
                    </td>
                    <td class="p-2 text-end">
                        <button-ui button-class="btn-primary btn-sm">
                            Редактировать
                        </button-ui>
                        <button-ui button-class="btn-danger btn-sm ms-3" @click="removeHandler(i.id)">
                            Удалить
                        </button-ui>
                    </td>
                </tr>
            </table>
        </div>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';
import { insert } from "@/composables/useTransmitter";

export default defineComponent({
    name: "AddSectionModal",
    components: {
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
	    const locoModels = ref([]);
	    const jobCategories = ref([]);
        const contractors = ref([]);

	    const getJob = computed(() => {
		    return (j: number) => {
			    let job: any = jobCategories.value.find((i: any) => i.id === j);
			    return job ? job.name : '';
		    };
	    });

	    const getContractor = computed(() => {
		    return (c: number) => {
			    let contractor: any = contractors.value.find((i: any) => i.id === c);
			    return contractor ? contractor.name : '';
		    };
	    });

	    const getLoco = computed(() => {
		    return (m: number) => {
			    let model: any = locoModels.value.find((i: any) => i.id === m);
			    return model ? model.model : '';
		    };
	    });

	    onMounted(() => {
		    api.info.get().then((r: any) => {
			    locoModels.value = r.data.locoModels;
			    jobCategories.value = r.data.jobCategories;
			    contractors.value = r.data.contractors;
		    });
	    });

	    const minDate = moment();
	    minDate.set('month', modal.data.monthInd);
	    minDate.set('date', 1);

	    const maxDate = moment();
	    maxDate.set('month', modal.data.monthInd);
	    maxDate.endOf('month');

	    let items = ref(modal.data.items.filter((i: any) => (
			    moment(i.dateStart).month() === modal.data.monthInd ||
			    moment(i.dateEnd).month() === modal.data.monthInd) ||
		    minDate.isBetween(moment(i.dateStart), moment(i.dateEnd)) ||
		    maxDate.isBetween(moment(i.dateStart), moment(i.dateEnd)),
	    ).filter((i: any) => i.row === modal.data.row && i.col === modal.data.column));

        function removeHandler(id: number) {
            api.newPlan.remove(id).then(r => {
                let ind = items.value.findIndex((i: any) => i.id === id);
                if (ind !== -1) {
                    items.value.splice(ind, 1);
                    modal.data.removeHandler(id);
                }
            });
        }

	    function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
	        modal,
            cancel,
            close,
	        items,
	        jobCategories,
	        locoModels,
	        getJob,
	        getLoco,
	        moment,
	        removeHandler,
	        getContractor,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
