<template>
    <modal-ui :id="id" size="modal-xl" title="Добавление накладной ТМЦ">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12 col-md-4">
                    <form-group-ui invalid-feedback="Укажите номер накладной" label="Номер" required>
                        <input-ui v-model="state.number" :is-invalid="isNumberError && state.visibleErrors" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-4">
                    <form-group-ui invalid-feedback="Выберите дату" label="Дата" required>
                        <date-picker v-model="state.date" class="inline-block h-full" :is24hr="true" mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input class="form-control"
                                        :class="{ 'is-invalid': isDateError && state.visibleErrors, 'bg-white': true }"
                                        as readonly :timezone="'Asia/Dhaka'" :value="inputValue" @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-4">
                    <b>Поставщик:</b>&nbsp;{{ state.contractorName }}<br />
                    <b>НДС:</b>&nbsp;{{ state.isVatIncludedInPrice ? 'Да' : 'Нет' }}
                </div>
                <div class="col-12">
                    <hr />
                    <p class="lead">Материалы</p>
                </div>
                <div class="col-12 row">
                    <div class="col-3 border">
                        Название ТМЦ
                    </div>
                    <div class="col-1 border">
                        Ед изм
                    </div>
                    <div class="col-2 border">
                        Количество
                    </div>
                    <div class="col-2 border">
                        Цена за единицу
                    </div>
                    <div class="col-2 border">
                        Выберите материал
                    </div>
                    <div class="col-2 border">
                        Количество
                    </div>
                </div>
                <div class="col-12 row" v-for="material in state.materials" :key="material.id">
                    <div class="col-3 border">
                        <form-group-ui invalid-feedback="Укажите наименование ТМЦ" required>
                            <input-ui class="mt-2" placeholder="Наименование ТМЦ" v-model="material.originalName"
                                :is-invalid="isNameError(material) && state.visibleErrors" />
                        </form-group-ui>
                    </div>
                    <div class="col-1 border">
                        <form-group-ui invalid-feedback="Укажите единицу измерения" required>
                            <input-ui class="mt-2" v-model="material.originalUnitDimension" placeholder="Ед изм"
                                :is-invalid="isUnitError(material) && state.visibleErrors" />
                        </form-group-ui>
                    </div>
                    <div class="col-2 border">
                        <form-group-ui invalid-feedback="Укажите количество ТМЦ" required>
                            <input-ui class="mt-2" type="number" step="any" v-model="material.originalCount"
                                placeholder="Количество ТМЦ"
                                :is-invalid="isCountInvError(material) && state.visibleErrors" />
                        </form-group-ui>
                    </div>
                    <div class="col-2 border">
                        <form-group-ui invalid-feedback="Укажите цену" required>
                            <input-ui class="mt-2" v-model="material.originalUnitPrice" step="any" type="number"
                                placeholder="Цена за единицу"
                                :is-invalid="isSumInvError(material) && state.visibleErrors" />
                        </form-group-ui>
                    </div>
                    <div class="col-2 border d-flex align-items-center">
                        {{ material.materialName }}
                    </div>
                    <div class="col-2 border d-flex align-items-center">
                        {{ material.actualCount }}&nbsp;{{ material.unit }}
                    </div>
                    <div class="col-12">
                        Общая сумма материала: {{ formatMoney(material.total) }} (без НДС: {{
                            formatMoney(state.isVatIncludedInPrice ?
                        material.total / 1.12 : material.total) }})
                        <div v-if="isTotalSumError(material) && state.visibleErrors" class="text-danger">
                            Общая сумма материала не бьется с количеством или ценой
                        </div>
                        <hr />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button-ui button-class="btn-primary">
                            Добавить
                        </button-ui>
                    </div>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import formatMoney from "@/utils/formatMoney";

type materialType = {
    id: number,
    originalName: string,
    originalUnitDimension: string,
    originalCount: number,
    originalUnitPrice: number,
    materialId: number,
    materialName: string,
    actualCount: number,
    unit: string,
    total: number,
};

type stateType = {
    id: number,
    number: string,
    date: string,
    contractorName: string,
    isVatIncludedInPrice: boolean,
    contractorId: number,
    materials: materialType[],
    visibleErrors: boolean,
    vat: boolean,
    loader: boolean,
};

export default defineComponent({
    name: "InvoiceFromToBeReceivedModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    methods: {
        formatMoney,
    },
    setup(props) {
        const modal = useModal(props.id);
        const supplier = ref([]);

        const state: stateType = reactive({
            id: 0,
            number: '',
            date: '',
            contractorName: '',
            contractorId: 0,
            isVatIncludedInPrice: false,
            materials: [],
            visibleErrors: false,
            vat: false,
            loader: false,
        });

        const isNumberError = computed(() => state.number.length === 0);
        const isDateError = computed(() => state.date.length === 0);
        const isNameError = computed(() => (material: materialType) => !material.originalName.length);
        const isUnitError = computed(() => (material: materialType) => !material.originalUnitDimension.length);
        const isCountInvError = computed(() => (material: materialType) => {
            return +material.originalCount <= 0 || isNaN(+material.originalCount)
        });
        const isSumInvError = computed(() => (material: materialType) => {
            return +material.originalUnitPrice <= 0 || isNaN(+material.originalUnitPrice)
        });
        const isTotalSumError = computed(() => (material: materialType) => {
            if (isCountInvError.value(material) || isSumInvError.value(material)) {
                return true;
            }
            const total = +material.originalCount * +material.originalUnitPrice;
            return total !== +material.total;
        });

        onMounted(() => {
            if (modal.data.hasOwnProperty('item')) {
                state.id = modal.data.item.id;
                state.contractorId = modal.data.item.contractorId;
                state.contractorName = modal.data.item.contractorName;
                state.isVatIncludedInPrice = modal.data.item.isVatIncludedInPrice;
                state.materials = modal.data.item.materials.map((protocolMaterial: any) => {
                    return {
                        id: protocolMaterial.id,
                        originalName: '',
                        originalUnitDimension: '',
                        originalCount: '',
                        originalUnitPrice: '',
                        materialId: protocolMaterial.material.id,
                        materialName: protocolMaterial.material.name,
                        actualCount: protocolMaterial.quantity,
                        unit: protocolMaterial.material?.unit || '',
                        total: protocolMaterial.total,
                    };
                });
            }
            api.supplier.get().then((i: any) => {
                supplier.value = i.data;
            });
        });

        function send() {
            const isErrorsExist = state.materials.some((m) => {
                return isNameError.value(m) || isUnitError.value(m) || isTotalSumError.value(m);
            });
            if (isNumberError.value || isDateError.value || isErrorsExist) {
                state.visibleErrors = true;
                return;
            }
            state.visibleErrors = false;
            state.loader = true;

            api.invoices.createFromProtocol({
                number: state.number,
                date: state.date,
                contractorId: state.contractorId,
                isVatIncludedInPrice: state.isVatIncludedInPrice,
                materials: state.materials,
            }).then(r => {
                modal.ok({
                    type: 'add',
                    item: r.data,
                });
            });
        }

        function close() {
            modal.cancel({
                zxc: 123,
            });
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            modal,
            cancel,
            close,
            state,
            send,
            isNumberError,
            isDateError,
            supplier,
            isNameError,
            isUnitError,
            isCountInvError,
            isSumInvError,
            isTotalSumError,
        };
    },
});
</script>

<style scoped lang="scss"></style>
