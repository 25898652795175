<template>
    <modal-ui
        :id="id"
        size="modal-xl"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите сотрудника"
                        label="Сотрудник"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isEmployeeIdError"
                            item-key="name"
                            :options="employees"
                            placeholder="Сотрудник"
                            :searchable="true"
                            :values="[state.employeeId]"
                            @on-selected="onEmployeeSelected" />
                    </form-group-ui>
                </div>
            </div>

            <div
                v-for="(item, itemInd) in state.items"
                :key="itemInd"
                class="row mb-4 pt-3 border-top position-relative">
	            <div class="d-inline-block position-absolute top-0 end-0 w-auto" v-if="state.items.length > 1">
		            <button-ui button-class="" @click="deleteHandler(item, itemInd)">
			            <div class="text-danger">х</div>
		            </button-ui>
	            </div>
                <div class="col-auto" style="width: 20%">
                    <form-group-ui
                        invalid-feedback="Выберите материал"
                        label="Материал"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isMaterialIdError(item)"
                            item-key="name"
                            :options="materials"
                            placeholder="Материал"
                            :searchable="true"
                            :values="[state.items[itemInd].materialId]"
                            @on-selected="(m: any) => onMaterialSelected(m, itemInd)" />
                    </form-group-ui>
                </div>
                <div class="col-auto"  style="width: 20%">
                    <div class="mb-3">
                        <label class="form-label d-flex">
                            <label class="form-check m-0">
                                <input
                                    v-model="state.items[itemInd].type"
                                    class="form-check-input"
                                    :name="'1' + itemInd"
                                    type="radio"
                                    :value="1">
                                <span class="form-check-label me-3">Секция</span>
                            </label>
                            <label class="form-check m-0">
                                <input
                                    v-model="state.items[itemInd].type"
                                    class="form-check-input"
                                    :name="'2' + itemInd"
                                    type="radio"
                                    :value="2">
                                <span class="form-check-label me-3">Участок</span>
                            </label>
                            <span class="text-danger">*</span>
                        </label>
                        <select-ui
                            v-if="state.items[itemInd].type === 1"
                            :is-invalid="state.visibleErrors && isSectionIdError(item)"
                            item-key="name"
                            :options="sections"
                            placeholder="Секция"
                            :searchable="true"
                            :values="[state.items[itemInd].sectionId]"
                            @on-selected="(s: any) => onSectionSelected(s, itemInd)" />

                        <select-ui
                            v-if="state.items[itemInd].type === 2"
                            :is-invalid="state.visibleErrors && isPlotIdError(item)"
                            item-key="name"
                            :options="plots"
                            placeholder="Участок"
                            :values="[state.items[itemInd].plotId]"
                            @on-selected="(p: any) => onPlotSelected(p, itemInd)" />
                        <div class="invalid-feedback">
                            Выберите значение
                        </div>
                    </div>
                </div>
                <div v-if="state.items[itemInd].type === 1" class="col-auto" style="width: 20%">
                    <form-group-ui
                        invalid-feedback="Укажите конструктив"
                        label="Конструктив">
                        <select-ui
                            :is-invalid="state.visibleErrors && isConstructIdError(item)"
                            :options="constructs"
                            placeholder="Конструктив"
                            :searchable="true"
                            :values="[state.items[itemInd].constructId]"
                            @on-selected="(c: any) => onConstructSelected(c, itemInd)" />
                    </form-group-ui>
                </div>
                <div class="col-auto" style="width: 20%">
                    <form-group-ui
                        invalid-feedback="Укажите количество"
                        :label="getCountLabel(state.items[itemInd].materialId)"
                        required>
                        <input-ui
                            v-model="state.items[itemInd].count"
                            type="number"
                            step="any"
                            :is-invalid="state.visibleErrors && isCountError(item)"
                            placeholder="Количество" />
                    </form-group-ui>
                </div>
                <div class="col-auto" style="width: 20%">
                    <form-group-ui
                        invalid-feedback="Укажите примечание"
                        label="Примечание">
                        <input-ui
                            v-model="state.items[itemInd].comment"
                            placeholder="Примечание" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 pt-3 border-top">
                    <button-ui
                        class="btn-outline-secondary"
                        type="button"
                        v-if="!state.id"
                        @click="addItem">
                        Добавить материал
                    </button-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';
import { insert } from "@/composables/useTransmitter";

export default defineComponent({
    name: "StockModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const materials = ref([]);
        const sections = ref([]);
        const areas = ref([]);
        const employees = ref([]);
        const measurements = ref([

        ]);
	    const constructs = ref([]);

	    const plots = ref([
		    {
			    id: 'Автоматный',
			    name: 'Автоматный',
		    },
		    {
			    id: 'Аккумуляторный',
			    name: 'Аккумуляторный',
		    },
		    {
			    id: 'Заготовительный',
			    name: 'Заготовительный',
		    },
		    {
			    id: 'Ремонтный',
			    name: 'Ремонтый',
		    },
		    {
			    id: 'Токарный',
			    name: 'Токарный',
		    },
		    {
			    id: 'Топливный',
			    name: 'Топливный',
		    },
		    {
			    id: 'Электроаппаратный',
			    name: 'Электроаппаратный',
		    },
		    {
			    id: 'Хозяйственный',
			    name: 'Хозяйственный',
		    },
		    {
			    id: 'Дизельный',
			    name: 'Дизельный',
		    },
		    {
			    id: 'Ходовой',
			    name: 'Ходовой',
		    },
		    {
			    id: 'Прочее',
			    name: 'Прочее',
		    },
	    ]);

        const state = reactive({
            title: '',
            btn: '',
	        id: 0,
	        materialId: 0,
	        items: [
		        {
                    materialId: 0,
			        type: 1,
			        sectionId: 0,
			        plotId: '',
			        count: 0,
			        comment: '',
			        constructId: 0,
		        },
	        ],
	        areaId: 0,
	        sectionId: 0,
	        measurement: '',
	        count: '',
	        comment: '',
	        employeeId: 0,
	        supercycle: null,
	        msg: '',
	        findNorm: false,
            visibleErrors: false,
            loader: false,
        });

	    //const isMaterialIdError = computed(() => !state.materialId);
	    const isAreaIdError = computed(() => !state.areaId);
	    //const isSectionIdError = computed(() => state.areaId === 1 ? !state.sectionId : false);
	    const isMeasurementError = computed(() => !state.measurement.length);
	    //const isCountError = computed(() => !state.count.length);
	    const isCommentError = computed(() => !state.comment.length);
	    const isEmployeeIdError = computed(() => !state.employeeId);

	    const isMaterialIdError = computed(() => {
		    return (item: any) => item.materialId === 0
	    })

	    const isSectionIdError = computed(() => {
		    return (item: any) => item.type === 1 && item.sectionId === 0
	    })

	    const isPlotIdError = computed(() => {
		    return (item: any) => item.type === 2 && item.plotId.length === 0
	    })

	    const isConstructIdError = computed(() => {
		    return (item: any) => item.constructId === 0
	    })

	    const isCountError = computed(() => {
		    return (item: any) => Number(item.count) <= 0
	    })


        const getCountLabel = computed(() => {
            return (materialId: any) => {
                if (materialId !== 0) {
                    let material = materials.value.find((m: any) => m.id === materialId);
					// @ts-ignore
                    return 'Количество в ' + material?.unit;
                } else {
                    return 'Количество';
                }
            };
        });

        const errors = computed(() => {
            return isMaterialIdError.value ||
		            isSectionIdError.value ||
		            isMeasurementError.value ||
		            isCountError.value ||
		            isEmployeeIdError.value;
        });

        onMounted(() => {
            if (modal.data.type === 'add') {
                state.title = 'Выдача ТМЦ';
                state.btn = 'Выдать';
                state.id = 0;
            } else {
	            state.title = 'Редактирование ТМЦ';
	            state.btn = 'Сохранить';
                state.id = modal.data.material.id;

                state.items = [{
                    materialId: modal.data.material.materialId,
			        type: !modal.data.material.sectionId ? 2 : 1,
			        sectionId: modal.data.material.sectionId,
			        plotId: modal.data.material.department || '',
			        count: modal.data.material.count,
			        comment: modal.data.material.comment,
			        constructId: modal.data.material.subgroupId,
                }];
                state.employeeId = modal.data.material.employeeId;
            }

            sections.value = modal.data.sections;

            api.sectionList.get().then((r: any) => {
	            sections.value = r.data;
            });

	        api.materials.modal().then((r: any) => {
		        materials.value = r.data;
	        });

            api.employees.modal().then((r: any) => {
                employees.value = r.data;
            });

	        api.constructs.get().then(r => constructs.value = r.data);
        });

        function addItem() {
            state.items.push({
                materialId: 0,
                type: 1,
                sectionId: 0,
                plotId: '',
                count: 0,
                comment: '',
                constructId: 0,
            });
        }

		function deleteHandler(item: any, itemInd: any) {
			state.items.splice(itemInd, 1)
		}

        function send() {

			let mat = state.items.map((i: any) => {
				if(i.type === 1) {
					return isMaterialIdError.value(i) || isSectionIdError.value(i) || isConstructIdError.value(i) || isCountError.value(i)
				}else{
					return isMaterialIdError.value(i) || isPlotIdError.value(i) || isCountError.value(i)
				}
			}).filter(i => i).length

	        if(isEmployeeIdError.value || mat) {
				state.visibleErrors = true
	        }else{
				state.visibleErrors = false
		        state.loader = true;
                let materials = state.items.map((i: any) => {
                    if (!!i.sectionId) {
                        return {
                            sectionId: i.sectionId,
                            materialId: i.materialId,
                            count: i.count,
                            comment: i.comment,
                            employeeId: state.employeeId,
                            subgroupId: i.constructId !== 0 ? i.constructId : null,
                        };
                    } else {
                        return {
                            department: i.plotId,
                            materialId: i.materialId,
                            count: i.count,
                            comment: i.comment,
                            employeeId: state.employeeId,
                        };
                    }
                });
                if (modal.data.type === 'add') {
                    api.stock.release.post({
                        materials: materials,
                    }).then((r: any) => {
                        if (r.data) {
                            modal.ok({
                                material: r.data,
                            });
                        }
                    }).finally(() => state.loader = false);
                    return;
                }
                api.stock.release.patch({
                    ...materials[0],
                    id: state.id,
                }).then((r: any) => {
                        if (r.data) {
                            modal.ok({
                                material: r.data,
                            });
                        }
                }).finally(() => state.loader = false);
	        }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

	    function onMaterialSelected(_:any, ind: any) {
		    state.items[ind].materialId = _.id;
	    }

	    function onPlotSelected(t: any, ind: any) {
		    state.items[ind].plotId = t.id;
	    }

	    function onAreaSelected(_:any) {
		    state.areaId = _.id;
	    }

	    function onSectionSelected(_:any, ind: any) {
		    state.items[ind].sectionId = _.id;
	    }

	    function onConstructSelected(_c: any, ind: any) {
		    state.items[ind].constructId = _c.id;
	    }

        function onEmployeeSelected(_: any) {
            state.employeeId = _.id;
        }

        return {
            ok,
            cancel,
            close,
            state,
	        send,
	        materials,
	        sections,
	        areas,
	        employees,
	        isMaterialIdError,
	        isAreaIdError,
	        isSectionIdError,
	        isMeasurementError,
	        isCountError,
	        isCommentError,
	        isEmployeeIdError,
	        onMaterialSelected,
	        onAreaSelected,
	        onSectionSelected,
	        onEmployeeSelected,
	        measurements,
	        onPlotSelected,
	        plots,
	        getCountLabel,
	        constructs,
	        onConstructSelected,
	        addItem,
	        deleteHandler,
	        isPlotIdError,
	        isConstructIdError
        };
    },
});
</script>

<style scoped lang="scss">

</style>
